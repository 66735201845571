// router.js 
// https://router.vuejs.org/zh/

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Index from '../pages/index'
import Search from '../pages/search'

export default new VueRouter({
    mode:"hash",  //vue-router路由模式的默认方式
    routes:[
        // { path: '/user/:id', component: User }
        { path:'/', component:Index },
        { path:'/search/', component:Search },
        { path:'/search/:s', component:Search },
        // s:搜索文字
    ]
})