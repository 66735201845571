<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
    <Welcome />
  </div>
</template>

<script>

  import { apiProductTypes } from '@/request/api'
  import { apiOriginals } from '@/request/api'
  import Welcome from '@/components/welcome'



  export default {
    name: 'App',
    components: {
      Welcome
    },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getBaseInfo()
      },
      getBaseInfo(){
        this.getProductType()
        this.getOriginal()
      },
      getProductType(){
          apiProductTypes({
            _limit: -1,
          }).then(res => {
            this.$store.state.productTypes = res
          })
      },
      getOriginal(){
          apiOriginals({
            _limit: -1,
          }).then(res => {
            this.$store.state.originals = res
          })
      }
    }
  }
</script>

<style>
  html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .container {
    /* background-color: #efefef; */
    width: 88%;
    max-width: 1400px;
    /* min-height: 100%; */
    margin: 100px auto 0;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    transition: all 0.2s ease;
  }

  @media screen and (max-width:768px) {
    .container {
      margin: 82px auto 0;
    }
  }
</style>