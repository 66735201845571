<template>
    <div class="bottom">
        <div class="wrap">
            <div class="logo">
                <img src="@/assets/bottom/logo.png" alt="logo">
            </div>
            <div class="link-box">
                <a @click="showGuziIntro = true">什么是谷子？</a>
                <!-- <a href="">友情链接</a> -->
                <a @click="showPrivacy = true">隐私政策</a>
                <!-- Todo 联系我们 -->
            </div>
            <div class="info-box">
                <div class="copyright">©2021-2023 南京米糯喵网络科技有限公司</div>
                <a class="icp" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备18002403号-4</a>
            </div>
        </div>
        <!-- 隐私政策 -->
        <van-dialog v-model="showPrivacy" title="隐私政策" :showConfirmButton="false" :showCancelButton="true"
            cancelButtonText="好的">
            <div class="dialog-time">
                2021.4
            </div>
            <div class="dialog-detail">
                <p><strong>恰谷子</strong> 坚信并尊重基本隐私权。</p>
                <p><strong>恰谷子</strong> 不收集，也不保存任何具有用户标识的信息。</p>
                <p><strong>恰谷子</strong> 会通过匿名的方式收集并记录：您创建或提供给我们的内容，您的应用、浏览器和设备信息，您的活动和您的位置信息。<strong>恰谷子</strong>
                    将这些信息存储在<strong>恰谷子</strong>数据库和第三方 Google Analytics 账户中，这些信息将确保以安全、加密、脱敏的方式保存。<strong>恰谷子</strong>
                    收集这些信息将用于网站访问分析和用户体验改进。</p>
            </div>
        </van-dialog>
        <!-- 什么是谷子？ -->
        <van-dialog v-model="showGuziIntro" title="什么是谷子？" :showConfirmButton="false" :showCancelButton="true"
            cancelButtonText="好的">
            <div class="dialog-detail">
                <p><strong>谷子</strong> 源于英文“Goods”，其日语读起来像“谷子”。</p>
                <p><strong>谷子</strong> 在二次元范指动漫周边产品，例如海报、手办、挂件等。</p>
                <p></p>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import {
    Dialog
} from 'vant';
Vue.use(Dialog);

export default {
    name: 'Bottom',
    props: {
        type: String
    },
    components: {},
    data() {
        return {
            loading: false,
            searchText: "",
            showPrivacy: false,
            showGuziIntro: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() { },
        go(where) {
            this.$router.push('/' + where)
            // 返回顶部
            document.body.scrollIntoView()
        },
    }
}
</script>

<style scoped>
.bottom {
    width: 100%;
    padding: 19px 0 24px;
    background: #F7F7F8;
    margin-top: 45px;
}

.wrap {
    width: 88%;
    max-width: 1400px;
    margin: 0 auto;
}

.logo {
    width: 84px;
}

.logo img {
    display: block;
    width: 100%;
}

.link-box {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.link-box a {
    display: block;
    font-size: 16px;
    line-height: 1;
    color: #03063A;
    margin-left: 36px;
    text-decoration: none;
    cursor: pointer;
}

.link-box a:first-child {
    margin-left: 0;
}

.info-box {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.copyright {
    font-size: 12px;
    color: #797B86;
    line-height: 1;
}

.icp {
    font-size: 12px;
    color: #797B86;
    line-height: 1;
    margin-left: 8px;
    text-decoration: none;
}

.icp:hover {
    color: #03063A;
}

.dialog-time {
    text-align: center;
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
    color: #D8D8D8;
}

.dialog-detail {
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.6;
    margin: 10px 0 20px;
    color: #333;
}

.dialog-detail p {
    margin-bottom: 6px;
}

@media screen and (max-width:768px) {
    .bottom {
        padding: 19px 0 20px;
    }

    .logo {
        width: 64px;
    }

    .link-box {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .link-box a {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: #03063A;
        margin-left: 20px;
        text-decoration: none;
        cursor: pointer;
    }

    .link-box a:first-child {
        margin-left: 0;
    }

    .info-box {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .copyright {
        font-size: 12px;
        color: #797B86;
        line-height: 1;
    }

    .icp {
        font-size: 12px;
        color: #797B86;
        line-height: 1;
        margin-left: 8px;
        text-decoration: none;
    }
}</style>