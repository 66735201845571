<template>
    <div class="contant-detail">
        <div class="top">
            <div class="shopinfo">
                <div class="shopimg" :style="'background-image:url('+detailInfo.shop.logo.url+'?x-oss-process=image/resize,m_fill,w_40,h_40/quality,Q_90/format,jpg'+')'"></div>
                <div class="shopname">{{detailInfo.shop.name}}</div>
                <div class="box-link">
                    <a :href="detailInfo.shop.weidianLink" target="_blank" v-if="detailInfo.shop.weidianLink">
                        <img src="@/assets/list/icon-weidian.png" alt="weidian">
                    </a>
                    <a :href="detailInfo.shop.taobaoLink" target="_blank" v-if="detailInfo.shop.taobaoLink">
                        <img src="@/assets/list/icon-taobao.png" alt="taobao">
                    </a>
                    <a :href="detailInfo.shop.pinduoduoLink" target="_blank" v-if="detailInfo.shop.pinduoduoLink">
                        <img src="@/assets/list/icon-pdd.png" alt="pdd">
                    </a>
                </div>
            </div>
            <div class="cover">
                <img :src="detailInfo.cover.url+'?x-oss-process=image/resize,m_fill,w_800,h_800/quality,Q_90/format,jpg'" alt="cover">
            </div>
        </div>
        <div class="bottom">
            <div class="infobox">
                <div class="title">
                    {{detailInfo.title}}
                </div>
                <div class="tags">
                    <div :class="'tag tag-type tag-'+detailInfo.type">
                        <span v-if="detailInfo.type == 'original'">原创</span>
                        <span v-if="detailInfo.type == 'official'">官谷</span>
                        <span v-if="detailInfo.type == 'reCreation'">同人谷</span>
                    </div>
                    <div class="tag tag-og" v-if="detailInfo && detailInfo.original">
                        原作：{{detailInfo.original.name}}
                    </div>
                    <div class="blank"></div>
                    <div class="feedback">
                        反馈
                    </div>
                </div>
                <div class="production">
                    <span class="span">制品：</span>
                    <span class="span" v-for="item in detailInfo.product_types">
                        {{item.name}}<span>、</span>
                    </span>
                </div>
            </div>
            <div class="btn-box">
                <div class="buttons">
                    <div class="btn-close" @click="close()">
                        <img src="@/assets/list/icon-close.png" alt="close">
                    </div>
                    <div class="btn-go" @click="goToLook(detailInfo.url)">
                        去看看
                    </div>
                    <div class="btn-share">
                        <img src="@/assets/list/icon-share.png" alt="share">
                    </div>
                </div>
                <div class="warning">
                    * 点击「去看看」将离开本站，跳转到第三方来源网站
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Detail',
        props: {
            detailInfo: Object,
        },
        components: {},
        data() {
            return {
                loading: false,
                searchText: ""
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            close() {
                // this.$parent.closeDetail()
                this.$emit('closeDetail');
            },
            goToLook(url){
                window.open(url,'_blank')
            },
            go(where) {
                this.$router.push('/' + where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .contant-detail {
        width: 86%;
        max-width: 360px;
        padding-top: 24px;
        margin: 0 auto;
        display: block;
        position: relative;
    }

    .top {
        width: 100%;
        display: block;
    }

    .shopinfo {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .shopimg {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .shopname {
        font-size: 14px;
        color: #03063A;
        line-height: 1;
        flex: 1;
        margin-left: 6px;
    }

    .box-link {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .box-link a {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-left: 6px;
        cursor: pointer;
    }
    .box-link a:hover {

        transform: scale(1.1,1.1);
    }

    .box-link a img {
        display: block;
        width: 100%;
        height: 100%;
    }

    .cover {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 16px;
    }

    .cover img {
        display: block;
        width: 100%;
    }

    .bottom{
        width: 100%;
    }
    .infobox {
        width: 100%;
        margin-top: 18px;
    }

    .title {
        display: none;
        font-weight: bold;
        font-size: 16px;
        color: #03063A;
        line-height: 1;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tags {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tag {
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        padding: 0 10px;
        font-size: 12px;
        color: #FFFFFF;
        margin-left: 12px;
    }

    .tag:first-child {
        margin-left: 0;
    }

    .tag-type {
        background-color: #FDE778;
    }

    .tag-og {
        background-color: #D8D9E2;
    }

    .tag-reCreation {
        background-color: #FDE778;
    }

    .tag-original {
        background-color: #CAEDA1;
    }

    .tag-official {
        background-color: #A3E1FF;
    }

    .blank {
        flex: 1
    }

    .feedback {
        padding: 0 10px;
        font-size: 12px;
        color: #797B86;
        line-height: 20px;
        cursor: pointer;
    }

    .production {
        background-color: #F7F7F8;
        width: 100%;
        height: 36px;
        line-height: 36px;
        border-radius: 10px;
        font-size: 12px;
        color: #9C9C9C;
        padding: 0 12px;
        margin-top: 15px;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .production .span {
        display: inline-block;
    }

    .production .span:last-child span {
        display: none;
    }

    .btn-box {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 11px;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%);
    }

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .btn-close {
        width: 40px;
        height: 40px;
        background: #F7F7F8;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .btn-close:hover{
        background-color: #D8D9E2;
    }

    .btn-close img {
        display: block;
        width: 14px;
    }

    .btn-go {
        background-image: linear-gradient(180deg, #FDE778 0%, #FACA43 100%);
        box-shadow: 0 10px 20px 0 #FFF0C5;
        border-radius: 13px;
        font-size: 16px;
        color: #03063A;
        line-height: 18px;
        margin: 0 10px;
        flex: 1;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }
    .btn-go:hover{
        transform: scale(1.05,1.05);
    }

    .btn-share {
        width: 40px;
        height: 40px;
        background-color: #F7F7F8;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-share:hover{
        background-color: #D8D9E2;
    }

    .btn-share img {
        display: block;
        width: 20px;
    }

    .warning {
        width: 100%;
        font-size: 12px;
        color: #797B86;
        text-align: center;
        line-height: 12px;
        margin-top: 12px;
        transform: scale(0.75, 0.75);
    }

    @media screen and (min-width:769px) {
        .contant-detail {
            width: 750px;
            max-width: 100%;
            padding: 57px 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }

        .top {
            width: 250px;
            display: block;
        }

        .bottom{
            width: 1px;
            flex:1;
            margin-left: 40px;
        }
        
        .infobox {
            margin-top: 44px;
            
        }

        .title {
            display: block;
        }

        .tags {
            margin-top: 16px;
        }

        .tag {
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
            padding: 0 12px;
        }

        .production {
            height: 40px;
            line-height: 40px;
            border-radius: 13px;
            font-size: 12px;
            padding: 0 16px;
            margin-top: 16px;
        }

        .btn-box {
            margin-top: 70px;
            padding-top: 0;
            padding-bottom: 0;
        }

        .buttons {
            width: 100%;
        }

        .btn-close {
            position: absolute;
            top:36px;
            right: 0
        }

        .btn-go {
            flex: none;
            width: 200px;
            margin-left: 0;
        }

        .warning {
            text-align: left;
            position: relative;
            left: -55px;
            /* transform: scale(1,1); */
        }
    }
</style>