<template>
    <div class="banner">
        <div class="banner-box">
            <div class="banner-img"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Banner',
        props: {
            type: String
        },
        components: {},
        data() {
            return {
                loading: false,
                searchText: ""
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            go(where) {
                this.$router.push('/' + where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .banner {
        width: 100%;
    }

    .banner-box {
        width: 100%;
        margin: 0 auto;
        background: #FDE778;
        border-radius: 72px;
        overflow: hidden;
        box-shadow: 0 5px 10px 0 #FFF0C5;
    }

    .banner-box .banner-img {
        width: 100%;
        height: 360px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/banner/deafult.jpg');
    }

    @media screen and (max-width:768px) {
        .banner-box {
            border-radius: 30px;
        }
        .banner-box .banner-img {
            height: 150px;
        }
    }
</style>