<template>
  <div class="list">
    <div class="art" v-for="item in guziData" @click="openDetail(item)">
      <!-- todo 阿里云oss裁剪正方形 -->
      <!-- todo 失效谷子效果、新谷子效果 -->
      <div class="cover-box">
        <img class="cover" :src="item.cover.url+'?x-oss-process=image/resize,m_fill,w_800,h_800/quality,Q_90/format,jpg'"
          alt="cover image">
      </div>
      <div class="title">{{item.title}}</div>
      <div class="tags">
        <div :class="'tag tag-type tag-'+item.type">
          <span v-if="item.type == 'original'">原创</span>
          <span v-if="item.type == 'official'">官谷</span>
          <span v-if="item.type == 'reCreation'">同人谷</span>
        </div>
        <!-- <div class="tag tag-origin">
            <span>咒术回战</span>
          </div> -->
        <div class="tag" v-for=" product_type in item.product_types">
          <span>{{product_type.name}}</span>
        </div>
      </div>
      <div class="info">
        <div class="shop-logo"
          :style="'background-image:url('+item.shop.logo.url+'?x-oss-process=image/resize,m_fill,w_40,h_40/quality,Q_90/format,jpg'+')'">
        </div>
        <div class="shop-name">
          {{item.shop.name}}
        </div>
        <div class="arrow">
          <img src="@/assets/list/arrow.png" alt="arrow">
        </div>
      </div>
    </div>
    <!-- 无限加载 -->
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" class="more">
      <div class="loading" slot="spinner">
        <van-loading type="spinner" />
      </div>
      <div class="no-more" slot="no-more">就这么多谷子哟 ^-^</div>
      <div class="no-more" slot="no-results">没找到相关谷子 >.<</div> </infinite-loading> <!-- 谷子详情 -->
          <van-popup v-model="showDetail" round position="bottom" :style="{ maxHeight: '95%' }">
            <Detail :detailInfo="detailInfo" @closeDetail="closeDetail" />
          </van-popup>
      </div>
</template>

<script>
  import Detail from '@/components/detail'
  import Vue from 'vue';
  import {
    Loading
  } from 'vant';
  Vue.use(Loading);
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'List',
    props: {
      guziData: Array,
      infiniteId: Number,
    },
    components: {
      InfiniteLoading,
      Detail
    },
    data() {
      return {
        loadingList: false,
        searchText: "",
        showDetail: false,
        detailInfo: {},
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {},
      openDetail(item) {
        this.detailInfo = item
        this.showDetail = true
      },
      closeDetail() {
        this.showDetail = false
        // this.detailInfo = {}
      },
      go(where) {
        this.$router.push('/' + where)
        // 返回顶部
        document.body.scrollIntoView()
      },
      infiniteHandler($state) {
        this.$parent.getGuzi($state)
      }
    }
  }
</script>

<style scoped>
  .list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .art {
    width: 23%;
    margin-right: 2.66%;
    margin-top: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
  }

  .art:hover {
    box-shadow: 0 5px 15px 0 #FFF0C5;
    transform: translateY(-3px);
  }

  .art:nth-child(4n) {
    margin-right: 0;
  }

  .cover-box{
    width: 100%;
    display: block;
    padding-bottom: 100%;
    position: relative;
    background-color: #F7F7F8;

  }

  .cover {
    width: 100%;
    display: block;
    position: absolute;
    top:0;
    left: 0;
    z-index: 2;
  }

  .title {
    width: 100%;
    padding: 0 10px;
    height: 0px;
    overflow: hidden;
    opacity: 0;
  }

  .tags {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: nowrap;
    padding: 0 10px;
    margin-top: 13px;
  }

  .tag {
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    background-color: #D8D9E2;
    color: #fff;
    margin-left: 6px;
    padding: 0 6px;
  }

  .tag:first-child {
    margin-left: 0;
  }

  .tag-type {
    background-color: #FDE778;
  }

  .tag-og {
    background-color: #D8D9E2;
  }

  .tag-reCreation {
    background-color: #FDE778;
  }

  .tag-original {
    background-color: #CAEDA1;
  }

  .tag-official {
    background-color: #A3E1FF;
  }

  .tag span {
    display: block;
    font-size: 13px;
    transform: scale(0.83, 0.83);
    white-space: nowrap;
    word-break: keep-all;
  }

  .info {
    margin-top: 11px;

    margin-bottom: 12px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .shop-logo {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-image: url('../assets/list/deafult.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .shop-logo img {
    width: 100%;
    display: block;
  }

  .shop-name {
    flex: 1;
    color: #797B86;
    font-size: 12px;
    margin-left: 6px;
  }

  .arrow {
    height: 20px;
    border-radius: 10px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F7F7F8;

  }

  .art:hover .arrow {
    background-color: #D8D9E2;

  }

  .arrow img {
    display: block;
    width: 14px;
  }

  .more {
    width: 100%;
    margin-top: 43px;
  }

  .loading {
    text-align: center;
  }

  .no-more {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #C8C8C8;
  }

  @media screen and (max-width:768px) {
    .art {
      width: 48%;
      margin-right: 4%;
      margin-top: 26px;
    }

    .art:nth-child(2n) {
      margin-right: 0;
    }

    .tags {
      padding: 0 8px;
      margin-top: 10px;
    }

    .tag {
      height: 12px;
      line-height: 12px;
      border-radius: 6px;
      margin-left: 4px;
      padding: 0 2px;
    }

    .tag span {
      transform: scale(0.67, 0.67);
    }

    .info {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .shop-logo {
      width: 12px;
      height: 12px;
      border-radius: 3px;
    }

    .shop-name {
      font-size: 10px;
      transform: scale(0.83, 0.83);
      margin-left: 0;
    }

    .arrow {
      height: 14px;
      border-radius: 7px;
      padding: 0 6px;
    }

    .arrow img {
      display: block;
      width: 12px;
    }

    .no-more {
      font-size: 14px;
    }

  }
</style>