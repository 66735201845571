<template>
  <div class="page">
    <Navbar type="index" />
    <div class="container">
      <Banner />
      <!-- <Title :title="'新谷子'" :intro="'近一周收获了 '+ guziCount +' 粒谷子'" :showFilterIcon="showFilterIcon" :openFilter="openFilter" /> -->
      <Title :title="'新谷子'" :intro="'共收获了 '+ guziCount +' 粒谷子'" :showFilterIcon="showFilterIcon" :openFilter="openFilter" />
      <List :guziData="guziData" />
    </div>
    <Bottom />
  </div>
</template>

<script>
  import Navbar from '@/components/navbar'
  import Banner from '@/components/banner'
  import Title from '@/components/title'
  import List from '@/components/list'
  import Bottom from '@/components/bottom'
  import { apiGuzi,apiGuziCount } from '@/request/api'
  

  export default {
    name: 'Index',
    // props: {
    //   msg: String
    // },
    components: {
      Navbar,
      Banner,
      Title,
      List,
      Bottom,
    },
    data() {
      return {
        loading: false,
        showFilterIcon: false,
        openFilter: false,
        guziData: [],
        guziCount: "?",
        start: 0,
        limit: 12,
        infiniteId: +new Date(),
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getGuziCount()
      },
      getGuzi($state) {
        apiGuzi({
          _start: this.start,
          _limit: this.limit,
          _sort: 'published_at:DESC'
        }).then(res => {
          if(res.length){
            this.start = this.start + this.limit,
            this.guziData.push(...res)
            $state.loaded();
          } else {
            $state.complete();
          }
          
        })
      },
      getGuziCount(){
        apiGuziCount({
          _limit: -1,
        }).then(res => {
          this.guziCount = res
        })
      }
    }
  }
</script>

<style scoped>
</style>