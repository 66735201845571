<template>
  <div :class="'navbar navbar-'+theType">
    <!--
      [type]
      navbar-search - 搜索页面
      navbar-index - 首页
    -->
    <div class="topbar"></div>
    <div class="navbox">
      <div class="wrap">
        <div class="logo" @click="go('')">
          <img src="@/assets/navbar/logo.png" alt="logo">
        </div>
        <div class="blank"></div>
        <div class="home" @click="go('')">
          <img src="@/assets/navbar/home.png" alt="go home">
        </div>
        <div class="search">
          <input type="text" v-model="$store.state.searchWord" placeholder="找谷子" @keyup.enter="search()">
          <div class="search-icon" @click="search()">
            <img src="@/assets/navbar/search.png" alt="search">
          </div>
        </div>
        <div class="btn" @click="showSubmit = true">
          提交新谷子
        </div>
      </div>
    </div>
    <!-- 提交新谷子 -->
    <van-popup v-model="showSubmit" closeable round position="bottom" :style="{ height: '67%' }">
        <div class="submit-box">
          功能暂未开放，敬请期待
        </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'Navbar',
    props: {
      type: String
    },
    components: {},
    data() {
      return {
        theType: "",
        oldWord: "",
        loading: false,
        showSubmit: false,
        searchFocus: false,
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.theType = this.type
      },
      search(){
        let width = document.body.clientWidth
        if(this.theType != "search" && this.theType != "ready" && width<=768){
          this.theType = "ready"
          // Todo 获取焦点
          // this.searchFocus = true
          return
        }
        let s = 'search/' + this.$store.state.searchWord
        let thisPath = this.$route.path
        if( thisPath == '/' + s){
          console.log("路由一样")
          return
        }
        this.go(s)
          this.searchFocus = false
      },
      go(where) {
        this.$router.push('/' + where)
        // 返回顶部
        document.body.scrollIntoView()
      }
    }
  }
</script>

<style scoped>
  /* 默认样式 */
  .navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  .topbar {
    width: 100%;
    height: 6px;
    background-color: #FDE778;
  }

  .navbox {
    width: 100%;
    /* background-color: rgba(255,255,255,0.8); */
    background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%);
  }

  .wrap {
    width: 88%;
    max-width: 1400px;
    margin: 0 auto;
    height: 92px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    width: 116px;
    margin-right: 10px;
    cursor: pointer;
  }

  .logo img {
    width: 100%;
    display: block;
    position: relative;
    top: -4px;
  }

  .blank {
    flex: 1
  }

  .home {
    background: #F7F7F8;
    border-radius: 9px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 10px;
  }

  .home img {
    display: block;
    width: 15px;
  }

  .search {
    background: #F7F7F8;
    border-radius: 9px;
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 10px;
  }

  /* .search-mobile{
    display: none;
  } */

  .search input {
    height: 40px;
    outline: none;
    border: none;
    background-color: #F7F7F8;
    color: #03063A;
    font-size: 16px;
    flex: 1;
    padding: 0 14px;
    width: 1px;
  }

  .search .search-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .search .search-icon img {
    width: 15px;
    display: block;
  }

  .btn {
    margin-left: 10px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    padding: 0 17px;
    background-image: linear-gradient(180deg, #FDE778 0%, #FACA43 100%);
    box-shadow: 0 5px 10px 0 #FFF0C5;
    border-radius: 9px;
    cursor: pointer;
  }

  .btn:hover {
    transform: scale(1.05, 1.05);
  }

  .submit-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* 首页样式 */
  .navbar-index .home {
    display: none;
  }

  /* 搜索页面样式 */
  .navbar-search .blank {
    display: none;
    flex: none;
  }

  .navbar-search .search {
    flex: 1
  }

  

  @media screen and (max-width:768px) {

    .topbar {
      height: 4px;
    }
    .wrap {
      height: 68px;
    }

    .logo {
      width: 87px;
      margin-right: 5px;
    }

    .logo img {
      top: -2px;
    }

    .home {
      width: 32px;
      height: 32px;
      margin: 0 5px;
    }

    .home img {
      display: block;
      width: 12px;
    }

    .search {
      width: 32px;
      height: 32px;
      margin: 0 5px;
    }


    .search input {
      display: none;
    }

    .search .search-icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .search .search-icon img {
      width: 12px;
    }

    .btn {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      padding: 0 10px;
      margin-left: 5px;
    }

    .btn:hover {
      transform: scale(1.05, 1.05);
    }

    /* 首页样式 */
    .navbar-index .home {
      display: none;
    }

    /* 搜索页面样式 */
    .navbar-search .search {
      flex: 1;
      background-color: #FDE778;
    }

    .navbar-search .search input{
      display: block;
      font-size: 14px;
      padding: 0 10px;
      background-color: #FDE778;
    }

    .navbar-search .btn {
      display: none;
    }
    /* 准备 */
    .navbar-ready .blank {
      display: none;
    }
    .navbar-ready .home {
      display: none;
    }
    .navbar-ready .search {
      flex: 1;
    }

    .navbar-ready .search input{
      display: block;
      font-size: 14px;
      padding: 0 10px;
    }

    .navbar-ready .btn {
      display: none;
    }
  }
  @media screen and (min-width:769px) {
      .search:hover{
        width: 210px;
      }

  }
</style>