<template>
    <van-popup v-model="showWelcome" closeable round position="bottom">
        <div class="welcome">
            <div class="title">欢迎来到恰谷子</div>
            <div class="small-title">在这里可以</div>
            <div class="contant-intro">
                <div class="box-intro">
                    <div class="img">
                        <img src="@/assets/welcome/welcome-new.png" alt="">
                    </div>
                    <div class="text">发现最新的谷子</div>
                </div>
                <div class="box-intro">
                    <div class="img">
                        <img src="@/assets/welcome/welcome-search.png" alt="">
                    </div>
                    <div class="text">查找你喜欢的谷子</div>
                </div>
                <div class="box-intro">
                    <div class="img">
                        <img src="@/assets/welcome/welcome-share.png" alt="">
                    </div>
                    <div class="text">分享谷子给朋友</div>
                </div>
            </div>
            <div class="btn" @click="showWelcome = false">
                我知道了～
            </div>
            <!-- Todo可以在右下角加一个形象 -->
        </div>
    </van-popup>
</template>

<script>
    import Vue from 'vue';
    import {
        Popup
    } from 'vant';
    Vue.use(Popup);

    export default {
        name: 'Welcome',
        props: {
            type: String
        },
        components: {},
        data() {
            return {
                showWelcome: false,
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                let hasShow = localStorage.getItem("hasWelcome")
                if(hasShow != 1){
                    this.showWelcome = true
                    localStorage.setItem("hasWelcome", 1)
                }
            },
            go(where) {
                this.$router.push('/' + where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .welcome {
        padding: 37px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #03063A;
    }

    .title {
        font-size: 24px;
        color: #000000;
        text-align: center;
        line-height: 1;
    }

    .small-title {
        font-size: 14px;
        color: #000000;
        text-align: center;
        line-height: 1;
        margin-top: 13px;
        margin-bottom: 43px;
    }

    .contant-intro {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .box-intro {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
    }

    .box-intro:first-child {
        margin-top: 0;
    }

    .box-intro .img {
        width: 60px;
        height: 60px;
        /* border-radius: 20px; */
        /* background: #D8D8D8; */
    }

    .box-intro .img img {
        width: 100%;
    }

    .box-intro .text {
        font-size: 18px;
        color: #000000;
        margin-left: 40px;
    }

    .btn {
        margin-top: 40px;
        width: 220px;
        height: 40px;
        line-height: 40px;
        background-image: linear-gradient(180deg, #FDE778 0%, #FACA43 100%);
        box-shadow: 0 10px 20px 0 #FFF0C5;
        border-radius: 13px;
        text-align: center;
        font-size: 18px;
        color: #03063A;
        cursor: pointer;
    }

    @media screen and (min-width:769px) {

        .title {
            font-size: 24px;
            color: #000000;
            text-align: center;
            line-height: 1;
        }

        .small-title {
            font-size: 14px;
            color: #000000;
            text-align: center;
            line-height: 1;
            margin-top: 13px;
            margin-bottom: 43px;
        }

        .contant-intro {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .box-intro {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 30px;
        }

        .box-intro:first-child {
            margin-top: 0;
        }

        .box-intro .img {
            width: 60px;
            height: 60px;
            /* border-radius: 20px; */
            /* background: #D8D8D8; */
        }

        .box-intro .img img {
            width: 100%;
        }

        .box-intro .text {
            font-size: 18px;
            color: #000000;
            margin-left: 40px;
        }

        .btn {
            margin-top: 40px;
            width: 220px;
            height: 40px;
            line-height: 40px;
            background-image: linear-gradient(180deg, #FDE778 0%, #FACA43 100%);
            box-shadow: 0 10px 20px 0 #FFF0C5;
            border-radius: 13px;
            text-align: center;
            font-size: 18px;
            color: #03063A;
            cursor: pointer;
        }
    }
</style>