// 参考网址：https://juejin.cn/post/6844903652881072141

// 在http.js中引入axios
import axios from 'axios'; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// vant的toast提示框组件，大家可根据自己的ui组件更改。
// import { Toast } from 'vant'; 



// 环境的切换
if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL = 'https://strapi.moemee.com';} 
else if (process.env.NODE_ENV == 'debug') {    
    axios.defaults.baseURL = 'https://strapi.moemee.com';
} 
else if (process.env.NODE_ENV == 'production') {    
    axios.defaults.baseURL = 'https://strapi.moemee.com';
}



// 设置请求超时时间
axios.defaults.timeout = 5000;


// post请求头的设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/* GET */
export function get(url, params){    
    return new Promise((resolve, reject) =>{        
        axios.get(url, {            
            params: params        
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
        })    
    });
}


/* POST */
export function post(url, params) {
    return new Promise((resolve, reject) => {
         axios.post(url, {            
            params: params        
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    });
}

/* PUT */
export function put(url, params){    
    return new Promise((resolve, reject) =>{        
        axios.put(url, {            
            params: params        
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
        })    
    });
}


/* DELETE */
export function del(url){    
    return new Promise((resolve, reject) =>{        
        axios.delete(url).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
        })    
    });
}