import { get, post, put, del } from './http'

// 获取基础数据
export const apiProductTypes = params => get('/product-types', params);
export const apiOriginals = params => get('/originals', params);

// 获取谷子列表
export const apiGuzi = (params) => get('/guzis', params);

// 获取谷子数量
export const apiGuziCount = (params) => get('/guzis/count', params);


// 例子
export const apiAddress = params => post('api/v1/users/my_address/address_edit_before', params);
// import { apiAddress } from '@/request/api';// 导入我们的api接口
// export default {        
//     name: 'Address',    
//     created () {
//         this.onLoad();
//     },
//     methods: {            
//         // 获取数据            
//         onLoad() {
//             // 调用api接口，并且提供了两个参数                
//             apiAddress({                    
//                 type: 0,                    
//                 sort: 1                
//             }).then(res => {
//                 // 获取数据成功后的其他操作
//                 ………………                
//             })            
//         }        
//     }
// }