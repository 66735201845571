<template>
  <div class="page">
    <Navbar type="search" />
    <div class="container">
      <Title title="找谷子" :intro="'共找到 '+ guziCount +' 粒谷子'" :showFilterIcon="showFilterIcon" :openFilter="openFilter" />
      <List :guziData="guziData" :infiniteId="infiniteId" />
    </div>
    <Bottom />
  </div>
</template>

<script>
  import Navbar from '@/components/navbar'
  import Title from '@/components/title'
  import List from '@/components/list'
  import Bottom from '@/components/bottom'
  import { apiGuzi,apiGuziCount } from '@/request/api'

  export default {
    name: 'Search',
    components: {
      Navbar,
      Title,
      List,
      Bottom,
    },
    data() {
      return {
        loading: false,
        showFilterIcon: true,
        openFilter: false,
        guziData: [],
        start: 0,
        limit: 12,
        nowSearchWord: "",
        guziCount: "?",
        infiniteId: +new Date(),
      }
    },
    watch:{
      $route(to, from) {
      //监听路由参数变化
        this.guziData = []
        this.start = 0
        this.limit = 12
        // this.getGuzi()
        this.infiniteId +=1
        this.getGuziCount()
        this.nowSearchWord = this.$route.params.s
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getGuziCount()
        if(this.$route.params.s){
          this.$store.state.searchWord = this.$route.params.s
          this.nowSearchWord = this.$route.params.s
        }
      },
      getGuzi($state) {
        apiGuzi({
          _start: this.start,
          _limit: this.limit,
          _sort: 'published_at:DESC',
          title_contains: this.$store.state.searchWord,
        }).then(res => {
          console.log(res)
          if(res.length){
            this.start = this.start + this.limit,
            this.guziData.push(...res)
            $state.loaded();
          } else {
            $state.complete();
          }
        })
      },
      getGuziCount(){
        apiGuziCount({
          _limit: -1,
          title_contains: this.$store.state.searchWord,
        }).then(res => {
          this.guziCount = res
        })
      }
    }
  }
</script>

<style scoped>
</style>