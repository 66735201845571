import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from "./router/index";

Vue.config.productionTip = false

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    productTypes: [], // 产品类型
    originals: [], // 原作
    searchWord: "" // 搜索词
  },
  // mutations: {
  //   increment (state) {
  //     state.count++
  //   }
  // }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
