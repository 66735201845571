<template>
    <div class="title">
        <!-- 标题 -->
        <div class="title-box">
            <div class="title-text">
                {{title}}
            </div>
            <div class="intro-text">
                {{intro}}
            </div>
            <!-- <div class="btn-box">
                <div class="filter-btn" v-if="showFilterIcon" @click="openFilter = !openFilter">
                    <img class="icon" src="@/assets/title/filter.png" alt="">
                    <div class="text" v-if="!openFilter">筛选</div>
                    <div class="text" v-if="openFilter">收起</div>
                </div>
            </div> -->
        </div>
        <!-- 筛选区 -->
        <!-- Todo -->
        <!-- <div :class="openFilter? 'filter-box' : 'filter-box hidden'">
            <div class="select-box">
                <div class="select-title">类型</div>
                <div class="select-text active">全部</div>
                <div class="select-text">原创</div>
                <div class="select-text">官谷</div>
                <div class="select-text">同人谷</div>
            </div>
            <div class="select-box">
                <div class="select-title">原作</div>
                <div class="select-text active">全部</div>
                <div class="select-text"  v-for="item in $store.state.originals">{{item.name}}>鬼灭之刃</div>
            </div>
            <div class="select-box">
                <div class="select-title">制品</div>
                <div class="select-text active">全部</div>
                <div class="select-text" v-for="item in $store.state.productTypes">{{item.name}}</div>
            </div>
        </div> -->
        <!-- <van-dropdown-menu class="dropdown-menu">
            <van-dropdown-item v-model="value1" :options="option1" />
            <van-dropdown-item v-model="value2" :options="option2" />
            <van-dropdown-item v-model="value3" :options="option3" />
        </van-dropdown-menu> -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import {
        DropdownMenu,
        DropdownItem
    } from 'vant';

    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    export default {
        name: 'Title',
        props: {
            title: String,
            intro: String,
            showFilterIcon: Boolean,
            openFilter: Boolean,
        },
        components: {},
        data() {
            return {
                loading: false,
                searchText: "",
                value1: 0,
                value2: 'a',
                value3: 'a',
                option1: [{
                        text: '类型',
                        value: 0
                    },
                    {
                        text: '新款商品',
                        value: 1
                    },
                    {
                        text: '活动商品',
                        value: 2
                    },
                ],
                option2: [{
                        text: '原作',
                        value: 'a'
                    },
                    {
                        text: '好评排序',
                        value: 'b'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                ],
                option3: [{
                        text: '制品',
                        value: 'a'
                    },
                    {
                        text: '好评排序',
                        value: 'b'
                    },
                    {
                        text: '销量排序',
                        value: 'c'
                    },
                ],
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {},
            go(where) {
                this.$router.push('/' + where)
                // 返回顶部
                document.body.scrollIntoView()
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 100%;
        margin-top: 30px;
    }

    .title-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .title-text {
        font-weight: bold;
        font-size: 30px;
        color: #03063A;
    }

    .intro-text {
        font-size: 16px;
        color: #797B86;
        margin-left: 20px;
        flex: 1;
        position: relative;
        top: 7px;
    }

    .btn-box {
        display: block;
    }

    .filter-box {
        display: block;
        overflow: hidden;
        max-height: 220px;
        width: 100%;
    }

    .filter-box.hidden {
        max-height: 0px;
    }

    .filter-btn {
        background: #F7F7F8;
        border-radius: 9px;
        padding: 0 12px;
        height: 36px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .filter-btn .icon {
        display: block;
        width: 13px;
    }

    .filter-btn .text {
        margin-left: 5px;
        color: #03063A;
        font-size: 16px;
    }

    .select-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;
    }

    .select-box:first-child {
        margin-top: 21px;
    }

    .select-title {
        background: #F7F7F8;
        border-radius: 8px;
        height: 40px;
        line-height: 40px;
        padding: 0 22px;
        color: #03063A;
        font-size: 18px;
        margin-right: 17px;
    }

    .select-text {
        border-radius: 8px;
        height: 40px;
        line-height: 40px;
        padding: 0 17px;
        /* margin-left: 12px; */
        cursor: pointer;

    }

    .select-text:hover {
        background: #F7F7F8;
    }

    .select-text.active {
        color: #FFD237;
    }

    .dropdown-menu {
        display: none;
        width: 100vw;
        position: relative;
        left: -7%;
        margin-top: 16px;
        box-shadow: none;
    }
    .dropdown-menu > div {
        box-shadow: none !important;
    }

    @media screen and (max-width:768px) {
        .title {
            margin-top: 35px;
        }

        .title-text {
            font-size: 20px;
        }

        .intro-text {
            font-size: 12px;
            margin-left: 10px;
            top: 3px;
        }

        .btn-box {
            display: block;
        }

        .filter-box {
            display: none;
        }

        .dropdown-menu {
            display: block;
        }

        .filter-btn {
            border-radius: 9px;
            padding: 0 10px;
            height: 28px;
        }

        .filter-btn .icon {
            width: 10px;
        }

        .filter-btn .text {
            margin-left: 2px;
            font-size: 12px;
        }

    }
</style>